<template>
  <div style="margin: 10px">
    <fp-input
      style="margin: 0px 5px; max-width: 200px"
      :label="$t('base.basic.filter')"
      @change="filterList"
      v-model="filter"
    />
    <fp-table-list
      :hasPagination="true"
      :items="filteredQuestionnaireList"
      :fields="questionnaireListFields"
      sortBy="CreatedAt"
    >
      <template #Title="row">
        {{ getTextByLanguage(row.item.Title, row.item.Languages) }}
      </template>
      <template #Description="row">
        {{ getTextByLanguage(row.item.Description, row.item.Languages) }}
      </template>
      <template #ProgressPercentage="row">
        <b-progress :value="row.item.FillingRate" :max="100"></b-progress>
        <div style="text-align: center">
          {{ row.item.FillingRate ? row.item.FillingRate + "%" : "0%" }}
        </div>
      </template>
      <template #CreatedAt="row">
        <div style="white-space: nowrap">
          {{ $convertDateToString(row.item.CreatedAt, "YYYY-MM-DD HH:mm") }}
        </div>
      </template>
      <template #FilledDate="row">
        <div style="white-space: nowrap">
          {{ $convertDateToString(row.item.FilledDate, "YYYY-MM-DD HH:mm") }}
        </div>
      </template>
      <template #Status="row">
        {{ $t(formStatuses[row.item.Status].Text) }}
      </template>
      <template #OpenQuestionnaire="row">
        <router-link
          :to="{
            path: 'Form',
            query: { Id: row.item.FormInstanceId },
          }"
          ><b-button>
            {{
              [
                formStatuses.Filled.Value,
                formStatuses.Evaluated.Value,
                formStatuses.UnderEvaluation.Value,
              ].includes(row.item.Status)
                ? $t("base.basic.view")
                : $t("base.basic.filling")
            }}
          </b-button></router-link
        >
      </template>
    </fp-table-list>
  </div>
</template>
<script>
import { FormLogic } from "../../../Logic/Backend/form";

export default {
  name: "QuestionnaireFillableList",
  components: {},
  data() {
    return {
      siteTitle: this.$t("client.questionnaires.title"),
      formStatuses: this.$enums.QuestionnaireStatus,
      questionnaireList: [],
      questionnaireListFields: [
        { key: "Title", label: this.$t("client.questionnaires.title") },
        {
          key: "Description",
          label: this.$t("client.questionnaires.description"),
        },
        {
          key: "ProgressPercentage",
          label: this.$t("client.questionnaires.percentage"),
        },
        {
          key: "CreatedAt",
          label: this.$t("client.questionnaires.startDateTime"),
          sortable: true,
        },
        {
          key: "FilledDate",
          label: this.$t("client.questionnaires.stopDateTime"),
          sortable: true,
        },
        { key: "Status", label: this.$t("client.questionnaires.status") },
        { key: "OpenQuestionnaire", label: "" },
      ],
      filteredQuestionnaireList: null,
      filter: null,
    };
  },
  props: {
    projectItems: Array,
  },
  watch: {
    async projectItems() {
      await this.getList();
    },
  },
  methods: {
    getTextByLanguage(textObject, languages) {
      if (languages.includes(this.$i18n.locale)) {
        return textObject[this.$i18n.locale];
      } else if (languages.includes("en")) {
        return textObject.en;
      } else {
        return textObject[languages[0]];
      }
    },
    filterList() {
      this.filteredQuestionnaireList = this.questionnaireList;
      if (this.filter) {
        this.filteredQuestionnaireList = this.$filterList(
          this.filter,
          this.questionnaireList,
          ["Title", "Description"]
        );
      }
    },
    //kérdőívek lista kérés
    async getList() {
      //kérés indítás
      const questionnaireListResponse = await FormLogic.getFormInstanceHeader({
        FormCategory: "Questionnaire",
        AssigneeId: this.$loggedUser.UserId,
        ProjectId: this.$loggedUser.SelectedProject.ProjectId,
      });
      //sikeres kérés?
      if (!questionnaireListResponse.Code) {
        //kérdőívek lista frissítés
        this.questionnaireList = this.filteredQuestionnaireList = questionnaireListResponse;
      } else {
        this.$bvToast.toast(questionnaireListResponse.Message, {
          title: this.$t("requestResponse.basic.errorGetNamedList", {
            name: this.$t(
              "healthProfessional.questionnaires.ownTab.errorFillableName"
            ),
          }),
          variant: "danger",
          solid: true,
          AutoHideDelay: 10000,
        });
      }
    },
  },
  //betöltéskor egyszer lefut
  async mounted() {
    //oldal címének beállítása
    this.$emit("title-changed", this.siteTitle);
    //kérdőívek listájának betöltése
    await this.getList();
  },
};
</script>
<style>
.questionnaire {
  padding: 50px 0px 100px 0px;
}
</style>
